<template>
  <div class="newsletter">
    <div class="container content">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-2 is-size-3-mobile">Der letzte Schritt</h1>
          <p class="subtitle is-4 is-size-5-mobile has-text-white">
            zu unserem Newsletter
          </p>
        </div>
      </div>
    </div>
    <div class="section is-medium">
      <div class="container">
        <div
          class="coloumn column is-8 is-offset-2 is-size-6 is-size-7-mobile spacetop"
        >
          <div class="notification is-info" v-if="success">
            <strong>Prima und vielen Dank!</strong><br />
            Wir haben die E-Mail-Adresse in unsere Kontaktdatenbank übernommen.
          </div>
          <div class="notification is-info" v-if="error">
            <strong>Oh nein! Hier stimmt etwas nicht…</strong><br />
            Wir konnten die E-Mail-Adresse nicht in unsere Kontaktdatenbank
            übernehmen. Ist die Mailadresse vielleicht schon hinterlegt?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "NewsletterStep2",
  data() {
    return {
      success: false,
      error: false,
      maildata: "",
    };
  },
  created: function () {
    this.maildata = this.$route.query.confirm;
    this.joinnewsletter();
  },
  methods: {
    async joinnewsletter() {
      try {
        const res = await axios.post(
          "/.netlify/functions/newsletterconfirm",
          { Hash: this.maildata },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.success = true;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        //console.log("ERROR:", error);
      }
    },
  },
};
</script>
